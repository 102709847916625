<template>
    <div>
        <Loader v-if="isLoadingUtr"/>
        <form class="form" novalidate="novalidate" id="st_urbanism_regulations_view_form">
            <template>
                <st-section
                :collapsible="false"
                :isCollapsed="false"
                key="st_urbanism_regulations_view_form_uat_section"
                collapsibleId="st_urbanism_regulations_view_form_uat_section"
                >
                    <template #body>
                        <div class="row">
                            <div class="col-sm col-md-6">
                                <div class="form-group">
                                    <label>
                                        {{ fields.territorial_administrative_unit_id.label }}:
                                    </label>
                                    <st-autocomplete
                                        v-model="model[fields.territorial_administrative_unit_id.name]"
                                        :options="fields.territorial_administrative_unit_id.options"
                                        :ref="fields.territorial_administrative_unit_id.name"
                                        :name="fields.territorial_administrative_unit_id.name"
                                        :fields="fields"
                                        :model="model"
                                        :formValidation="fv"
                                        searchable
                                        :disabled="isDisabled"
                                        @change="uatChanged($event)"
                                    >
                                    </st-autocomplete>
                                </div>
                            </div>
                            <div class="col-sm col-md-6">
                                <div class="form-group">
                                    <label>
                                        {{ fields.utr_id.label }}
                                    </label>
                                    <st-autocomplete
                                        v-model="model[fields.utr_id.name]"
                                        :options="utrsOptions"
                                        :ref="fields.utr_id.name"
                                        :fields="fields"
                                        :model="model"
                                        :name="fields.utr_id.name"
                                        :formValidation="fv"
                                        searchable
                                        :disabled="isDisabled"
                                        @change="utrChanged($event)"
                                    >
                                    </st-autocomplete>
                                </div>
                            </div>
                        </div>
                    </template>
                </st-section>
            </template>

            <template>
                <st-section
                :collapsible="false"
                :isCollapsed="false"
                ref="st_urbanism_regulations_view_form_existing_utr_section_0"
                key="st_urbanism_regulations_view_form_existing_utr_section_0"
                collapsibleId="st_urbanism_regulations_view_form_existing_utr_section_0"
                >
                    <template #header>
                        {{ $t('URBANISM_REGULATIONS_EDIT.SECTION_EXISTING_UTR') }}
                    </template>
                    <template #body>
                        <div class="row mt-4">
                            <div class="col-12">
                                <hr>
                            </div>
                            <div class="col-sm col-md-6">
                                <div class="form-group">
                                    <label>
                                        {{ fields.name.label }}
                                    </label>
                                    <div>
                                        <st-input-text
                                            :ref="fields.name.name"
                                            :name="fields.name.name"
                                            v-model="model[fields.name.name]"
                                            :disabled="isDisabled"
                                            :readOnly="true"

                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm col-md-6">
                                <div class="form-group">
                                    <label>
                                        {{ fields.symbol.label }}
                                    </label>
                                    <div>
                                        <st-input-text
                                            :ref="fields.symbol.name"
                                            :name="fields.symbol.name"
                                            v-model="model[fields.symbol.name]"
                                            :disabled="isDisabled"
                                            :readOnly="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </st-section>
            </template>

            <template>
                <st-section
                :collapsible="true"
                :isCollapsed="false"
                ref="st_urbanism_regulations_view_form_existing_utr_section_1"
                key="st_urbanism_regulations_view_form_existing_utr_section_1"
                collapsibleId="st_urbanism_regulations_view_form_existing_utr_section_1"
                >
                    <template #header>
                        {{ $t('URBANISM_REGULATIONS_EDIT.FIELDS.RLU_RT') }}
                    </template>
                    <template #body>
                        <div class="row">
                            <div class="col-12">
                                <hr>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <st-richtext-editor
                                            formId="st_urbanism_regulations_view_form"
                                            :ref="fields.rlu_rt.name"
                                            :name="fields.rlu_rt.name"
                                            v-model="model[fields.rlu_rt.name]"
                                            :readOnly="true"
                                            :toolbarEnabled="false"
                                            :showCharCount="false"
                                            :isRequired="false"
                                            :maxLength="rteMaxLength"
                                        />
                                </div>
                            </div>
                        </div>
                    </template>
                </st-section>
            </template>

            <template>
                <st-section
                :collapsible="true"
                :isCollapsed="false"
                ref="st_urbanism_regulations_view_form_existing_utr_section_2"
                key="st_urbanism_regulations_view_form_existing_utr_section_2"
                collapsibleId="st_urbanism_regulations_view_form_existing_utr_section_2"
                >
                    <template #header>
                        {{ $t('URBANISM_REGULATIONS_EDIT.FIELDS.RLU_RJ') }}
                    </template>
                    <template #body>
                        <div class="row">
                            <div class="col-12">
                                <hr>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <st-richtext-editor
                                            formId="st_urbanism_regulations_view_form"
                                            :ref="fields.rlu_rj.name"
                                            :name="fields.rlu_rj.name"
                                            v-model="model[fields.rlu_rj.name]"
                                            :readOnly="true"
                                            :toolbarEnabled="false"
                                            :showCharCount="false"
                                            :isRequired="false"
                                            :maxLength="rteMaxLength"
                                        />
                                </div>
                            </div>
                        </div>
                    </template>
                </st-section>
            </template>

            <template>
                <st-section
                :collapsible="true"
                :isCollapsed="false"
                ref="st_urbanism_regulations_view_form_existing_utr_section_3"
                key="st_urbanism_regulations_view_form_existing_utr_section_3"
                collapsibleId="st_urbanism_regulations_view_form_existing_utr_section_3"
                >
                    <template #header>
                        {{ $t('URBANISM_REGULATIONS_EDIT.FIELDS.RLU_RE') }}
                    </template>
                    <template #body>
                        <div class="row">
                            <div class="col-12">
                                <hr>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <st-richtext-editor
                                            formId="st_urbanism_regulations_view_form"
                                            :ref="fields.rlu_re.name"
                                            :name="fields.rlu_re.name"
                                            v-model="model[fields.rlu_re.name]"
                                            :readOnly="true"
                                            :toolbarEnabled="false"
                                            :showCharCount="false"
                                            :isRequired="false"
                                            :maxLength="rteMaxLength"
                                        />
                                </div>
                            </div>
                        </div>
                    </template>
                </st-section>
            </template>

            <template>
                <st-section
                :collapsible="true"
                :isCollapsed="false"
                ref="st_urbanism_regulations_view_form_existing_utr_section_4"
                key="st_urbanism_regulations_view_form_existing_utr_section_4"
                collapsibleId="st_urbanism_regulations_view_form_existing_utr_section_4"
                >
                    <template #header>
                        {{ $t('URBANISM_REGULATIONS_EDIT.FIELDS.RLU_POT') }}
                    </template>
                    <template #body>
                        <div class="row">
                            <div class="col-12">
                                <hr>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <st-richtext-editor
                                            formId="st_urbanism_regulations_view_form"
                                            :ref="fields.rlu_pot.name"
                                            :name="fields.rlu_pot.name"
                                            v-model="model[fields.rlu_pot.name]"
                                            :readOnly="true"
                                            :toolbarEnabled="false"
                                            :showCharCount="false"
                                            :isRequired="false"
                                            :maxLength="rteMaxLength"
                                        />
                                </div>
                            </div>
                        </div>
                    </template>
                </st-section>
            </template>


            <template>
                <st-section
                :collapsible="true"
                :isCollapsed="false"
                ref="st_urbanism_regulations_view_form_existing_utr_section_5"
                key="st_urbanism_regulations_view_form_existing_utr_section_5"
                collapsibleId="st_urbanism_regulations_view_form_existing_utr_section_5"
                >
                    <template #header>
                        {{ $t('URBANISM_REGULATIONS_EDIT.FIELDS.RLU_CUT') }}
                    </template>
                    <template #body>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="font-size-h5 font-weight-bold">

                                    </label>
                                    <div>
                                        <st-richtext-editor
                                            formId="st_urbanism_regulations_view_form"
                                            :ref="fields.rlu_cut.name"
                                            :name="fields.rlu_cut.name"
                                            v-model="model[fields.rlu_cut.name]"
                                            :readOnly="true"
                                            :toolbarEnabled="false"
                                            :showCharCount="false"
                                            :isRequired="false"
                                            :maxLength="rteMaxLength"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </st-section>
            </template>


            <template>
                <st-section
                :collapsible="true"
                :isCollapsed="false"
                ref="st_urbanism_regulations_view_form_existing_utr_section_6"
                key="st_urbanism_regulations_view_form_existing_utr_section_6"
                collapsibleId="st_urbanism_regulations_view_form_existing_utr_section_6"
                >
                    <template #header>
                        {{ $t('URBANISM_REGULATIONS_EDIT.FIELDS.RLU_RDA') }}
                    </template>
                    <template #body>
                        <div class="row">
                            <div class="col-12">
                                <hr>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="font-size-h5 font-weight-bold">

                                    </label>
                                    <div>
                                        <st-richtext-editor
                                            formId="st_urbanism_regulations_view_form"
                                            :ref="fields.rlu_rda.name"
                                            :name="fields.rlu_rda.name"
                                            v-model="model[fields.rlu_rda.name]"
                                            :readOnly="true"
                                            :toolbarEnabled="false"
                                            :showCharCount="false"
                                            :isRequired="false"
                                            :maxLength="rteMaxLength"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </st-section>
            </template>

        </form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StRichtextEditor from '@/shared/components/StRichtextEditor';
import { UrbanismRegulationsViewModel } from "@/modules/applications/models/urbanism-regulations-view-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";

const rluCategories = ['RLU_RT', 'RLU_RJ', 'RLU_RE', 'RLU_POT', 'RLU_CUT', 'RLU_RDA'];

const { fields } = UrbanismRegulationsViewModel;

const formSchema = new FormSchema([
    fields.is_existing_utr,
    fields.ru_scope,
    fields.territorial_administrative_unit_id,
    fields.territorial_administrative_unit,
    fields.utr_id,
    fields.utr,
    fields.name,
    fields.symbol,
    fields.rlu_rt,
    fields.rlu_rj,
    fields.rlu_re,
    fields.rlu_rda,
    fields.rlu_pot,
    fields.rlu_cut,
]);

export default {
    name: "UrbanismRegulationsViewForm",
    components: {
        StRichtextEditor,
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false,
        },
        canEditStaff: {
            type: Boolean,
            default: false,
        },
        canReviewStaff: {
            type: Boolean,
            default: false,
        },
        ruScope: {
            type: String,
            require: true,
        },
    },
    data() {
        return {
            fields,
            model: null,
            rules: formSchema.rules(),
            fv: null,
            existingUtr: null,
        };
    },
    computed: {
        ...mapGetters({
            defaultCountyId: "shared/defaultCountyId",
            record: "applications/form/record",
            loading: 'shared/loading',
        }),
        rteMaxLength() {
            return 1000000;
        },
        isDisabled() {
            return this.viewMode && !this.canEditStaff;
        },
        utrsOptions() {
            return this.fields.utr_id.options.filter(v => v.ru_scope === this.ruScope);
        },
        isLoadingUtr() {
            return this.loading['urbanismRegulations/getItem'];
        },
    },
    methods: {
        ...mapActions({
            fetchRluZones: "applications/urbanismRegulationZonesForm/get",
        }),
        uatChanged() {
            const uats = this.fields.territorial_administrative_unit_id.options;
            this.model.territorial_administrative_unit = uats.find( v => v.value === this.model.territorial_administrative_unit_id)?.text;
        },
        async utrChanged() {
            if(this.model.utr_id) {
                const curUtr = this.utrsOptions.find( v => v.value === this.model.utr_id);
                this.model.utr = curUtr?.text;

                const utrZones = await this.fetchRluZones(this.model.utr_id);
                this.model.symbol = curUtr.symbol;
                this.model.name = curUtr.name;

                for (let i = 0; i < rluCategories.length; i++) {
                    const key = rluCategories[i].toLowerCase();
                    const utrZone = utrZones.find( u => u.category_key == rluCategories[i]);
                    this.model[key] = utrZone.content_html ?? '';
                };
            } else {
                this.model.name = '';
                this.model.symbol = '';
                this.model.utr = null;
            }
        },
    },
    async created() {
        if (this.item) {
            this.model = formSchema.initialValues(this.item);
        } else {
            this.model = formSchema.initialValues({
                is_existing_utr: true,
                ru_scope: this.ruScope,
                county_id: this.defaultCountyId,
            });
        }
    },
    mounted() {
        this.fv = createFormValidation("st_urbanism_regulations_view_form", this.rules);
    },
};
</script>
